import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import jq3 from 'jquery';

var _ = require('lodash');

const ResistantDocResult = (props) => {
  const element = document.querySelector("#new-document");

  const [documentResult, setDocumentResults] = useState([]);
  const [modalContent, setModalContent] = useState(<div></div>);

  useEffect(() => {
    function formAjaxSuccess(event) {
      const [_data, _status, xhr] = event.detail;
      var file = document.getElementById("document").files[0];
      var fileReader = new FileReader();

      function addDataToDocumentResults(fileData) {
        var documentData = { fileData: fileData, data: _data};
        setDocumentResults([...documentResult, documentData])
      }

      switch (_data.result.file_type) {
        case 'pdf':
          fileReader.onload = function() {
            var typedarray = new Uint8Array(this.result);
            addDataToDocumentResults(typedarray);
          }
          fileReader.readAsArrayBuffer(file);
          break;
        case 'image':
          fileReader.onload = function() {
            var fileBase64 = this.result;
            var currentImage = new Image();
            currentImage.onload = function() {
              var imageData = {imageSrc: fileBase64, imageWidth: currentImage.width, imageHeight: currentImage.height}
              addDataToDocumentResults(imageData);
            }
            currentImage.src = this.result;
          }
          fileReader.readAsDataURL(file);
          break;
      }
    }

    element.addEventListener("ajax:success", formAjaxSuccess);

    return function cleanup() {
      element.removeEventListener("ajax:success", formAjaxSuccess);
    };
  })

  useEffect(() => {
    function shownModal(event) {
      var button = jq3(event.relatedTarget);
      var resultIndex = button.data('resultindex');
      var currentDocumentResult = documentResult[resultIndex];

      var indicators = currentDocumentResult.data.result.indicators.map((indicator, index) => {
        return(
          <div className="card" key={index}>
            <div className="card-body">
              <h5 className="card-title">{indicator.title} - ({indicator.type})</h5>
              <p className="card-text">{indicator.description}</p>
            </div>
          </div>
        )
      })

      switch (currentDocumentResult.data.result.file_type) {
        case 'pdf':
          var loadingTask = pdfjsLib.getDocument(currentDocumentResult.fileData);

          loadingTask.promise.then(function(pdf) {
            var pageNumberRange = _.range(pdf.numPages);
            var pdfPages = pageNumberRange.map((number) => {
              return(
                <div key={number} className="p-3 rounded-lg" style={{backgroundColor: "rgb(224, 224, 224)"}}>
                  <div className="d-inline-block position-relative mw-100 align-middle">
                    <canvas ref={React.createRef()}>Page number {number}</canvas>
                    <svg ref={React.createRef()} className="position-absolute" style={{top: 0, left: 0, height: "100%"}}>
                      <mask id={"mask-" + number}>
                        <rect x="0" y="0" width="100%" height="100%" fill="#222222"></rect>
                      </mask>
                      <rect mask={"url(#mask-" + number + ")"} width="100%" height="100%" fill="black"></rect>
                    </svg>
                  </div>
                </div>
              )
            })

            var modalcontent = (
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="pdfAnalysisResultLabel">{currentDocumentResult.data.file_name}</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div className="mr-3">{indicators}</div>
                    </div>
                    <div>{pdfPages}</div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            )

            setModalContent(modalcontent);

            pageNumberRange.forEach(function(pageNumber) {
              pdf.getPage(pageNumber + 1).then(function(page) {
                var desiredWidth = 500;
                var viewport = page.getViewport({ scale: 1, });
                var scale = desiredWidth / viewport.width;
                var scaledViewport = page.getViewport({ scale: scale, });

                var canvas = pdfPages[pageNumber].props.children.props.children[0].ref.current;
                var svgRoot = pdfPages[pageNumber].props.children.props.children[1].ref.current;

                var context = canvas.getContext('2d');
                canvas.height = scaledViewport.height;
                canvas.width = scaledViewport.width;

                svgRoot.setAttribute("height", scaledViewport.height);
                svgRoot.setAttribute("width", scaledViewport.width);

                var renderContext = {
                  canvasContext: context,
                  viewport: scaledViewport
                };

                var renderTask = page.render(renderContext);

                var coordinates =_.flatMapDeep(currentDocumentResult.data.result.indicators, function(indicator) {
                  return _.map(indicator.metadata, function(metadata) {
                    return _.filter(metadata, function(data) {
                      return (_.has(data, 'bbox') && (data.page === pageNumber));
                    })
                  })
                })

                renderTask.promise.then(function () {
                  context.strokeStyle = 'red';
                  _.forEach(coordinates, function(coordinate) {
                    var bbox = coordinate.bbox;
                    var x = bbox.x * scale;
                    var y = scaledViewport.height - (bbox.y * scale);
                    var width = bbox.width * scale;
                    var height = -(bbox.height * scale);
                    context.strokeRect(x, y, width, height);
                  });
                });
              });
            })
          })
          break;
        case 'image':
          var documentImageRef = React.createRef();
          var coordinates =_.flatMapDeep(currentDocumentResult.data.result.indicators, function(indicator) {
            return _.map(indicator.metadata, function(metadata) {
              return _.filter(metadata, function(data) {
                return (_.has(data, 'bbox'));
              })
            })
          })

          var imgWidth = currentDocumentResult.fileData.imageWidth;
          var imgHeight = currentDocumentResult.fileData.imageHeight

          var pdfPages =
            <div className="p-3 rounded-lg" style={{backgroundColor: "rgb(224, 224, 224)"}}>
              <div ref={documentImageRef} className="d-inline-block position-relative mw-100 align-middle">
                <img src={currentDocumentResult.fileData.imageSrc} alt="Image to analyze" className="img-fluid" />
                <svg ref={React.createRef()} width={imgWidth} height={imgHeight} className="position-absolute mw-100" style={{top: 0, left: 0, height: "100%"}}>
                  <mask id={"mask-1"}>
                    <rect x="0" y="0" width="100%" height="100%" fill="#222222"></rect>
                  </mask>
                  <rect mask={"url(#mask-1)"} width="100%" height="100%" fill="black"></rect>
                </svg>
                {coordinates.map((coordinate, index) => {
                  var bbox = coordinate.bbox;
                  var left = (bbox.x / imgWidth * 100) + "%";
                  var bottom = (bbox.y / imgHeight * 100) + "%";
                  var width = (bbox.width / imgWidth * 100) + "%";
                  var height = (bbox.height / imgHeight * 100) + "%";

                  return(<div key={index} style={{bottom: bottom, left: left, width: width, height: height, borderStyle: "solid", borderColor: "red", position: "absolute", borderWidth: "1.6px", zIndex: 1300}}></div>)
                })}

              </div>
            </div>

          var modalcontent = (
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="pdfAnalysisResultLabel">{currentDocumentResult.data.file_name}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col">
                    <div className="mr-3">{indicators}</div>
                  </div>
                  <div className="col">{pdfPages}</div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          )
          setModalContent(modalcontent);
          break;
      }
    }

    function hideModal(event) {
      setModalContent(<div>...</div>);
    }

    var pdfAnalysisResultModalEl = document.getElementById('pdfAnalysisResult')
    pdfAnalysisResultModalEl.addEventListener('shown.bs.modal', shownModal)
    pdfAnalysisResultModalEl.addEventListener('hide.bs.modal', hideModal)

    return function cleanup() {
      pdfAnalysisResultModalEl.removeEventListener('shown.bs.modal', shownModal)
      pdfAnalysisResultModalEl.removeEventListener('hide.bs.modal', hideModal)
    };
  });

  return(
    <>
      <div className="d-flex flex-column-reverse">
        {documentResult.map((document, index) =>
          <div className="card mb-3" key={index}>
            <div className="card-body">
              <h5 className="card-title">{document.data.file_name}</h5>
              <p className="card-text">Result: {document.data.result.score}</p>
              <button data-resultindex={index} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#pdfAnalysisResult">View Details</button>
            </div>
          </div>
        )}
      </div>

      <div className="modal fade" id="pdfAnalysisResult" tabIndex="-1" aria-labelledby="pdfAnalysisResultLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          {modalContent}
        </div>
      </div>
    </>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('resistant-doc-result')

  if (node != null) {
    ReactDOM.render(<ResistantDocResult/>, node)
  }
})
